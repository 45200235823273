import React, { useRef } from 'react'
import './Join.css'
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();
  function email(){
    var url = "https://mail.google.com/mail/u/0/?fs=1&to=serginoguerad@gmail.com&su=Contacto&tf=cm"
    window.open(url,'_blank').focus();
    }
    const handleJoin = (e)=> {
        e.preventDefault()
        emailjs.sendForm(
            'service_jqn5flv',
            'template_5fbt3fr',
            form.current,
            'ddYcz13MvW01UFF5u'
        )
        .then((result)=>{
            console.log('done')
        },
        (error)=>{
            console.log(error)
        }
        )
    }
  return (
   <><div className="Join" id='join-us'>
          <div className="left-j">
              <hr />
              <div><span className='stroke-text'>Preparado para</span><span>elevar</span></div>
              <div><span>tu cuerpo</span><span className='stroke-text'>conmigo?</span></div>
          </div>
          <div className="right-j">
              <form className="email-container" netlify ref={form} onSubmit={handleJoin}>
                  <input netlify type="email" name='user_email' placeholder='Correo electronico' />
                  <button type='submit' className="btn btn-j" onClick={email}>Empezar!</button>
              </form>
          </div>
      </div>
    
    </>    
  )
}

export default Join