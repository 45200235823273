import './App.css';
import Hero from './Components/Hero/Hero';
import Plans from './Components/Plans/Plans';
import Programs from './Components/Programs/Programs';
import Reasons from './Components/Reasons/Reasons';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';
import Join from './Components/Join/Join';
import Contact from './Components/Contact/Contact';
import FAQ from "./FAQ/Faq"
import React, {useState} from 'react'
  

function App() {

  const [faqs, setFaqs] = useState([
    {
      title: '¿Cómo es el proceso de compra y qué ocurre después de realizar el pago?',
      content: `Una vez realizada la compra recibirás un formulario para rellenar con tu información, de esta manera el plan que se realice será totalmente individualizado. Cuando el pago sea efectivo y se haya entregado el formulario correctamente se procederá a la realización de la planificación de entrenamiento. El plazo de entrega es de 48-72 horas laborables, piensa que estamos haciendo algo completamente individualizado para ti, lleva tiempo.`,
      open: false
     },
     {
      title: '¿Quiero empezar, como podemos hacerlo?',
      content:'Para empezar, solamente tienes que escoger el plan que mejor se te adapte y hacer el pago. Una vez realizado el pago me pondré en contacto contigo en un margen de 24/48 horas. ',
      open: false
     },
     {
      title: '¿Cual es tu horario de resolución de dudas?',
      content:'Todas las dudas serán en un margen de 24h (suele ser antes)',
      open: false
     },
     {
      title: '¿Qué métodos de pago aceptas?',
      content:'El método de pago será online y mediante targeta de crédito/débito. Si no dispones ponte en contacto conmigo al +34 686 19 27 51',
      open: false
     },
     {
      title: 'Polítca de devolución',
      content:'Una vez realizado el pago del servicio, no se realizará la devolución del dinero por falta de compromiso por parte del cliente. A no ser que el cliente sufra de alguna anomalía que no le permíta entrenar, necesitando justificante médico de la misma.',
      open: false
     },
]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="App">
      <Hero/>
      <Reasons/>
      <Plans/>
      <Testimonials/>
      <div className='faqcontainer'>
          <div className="left-t">
            <span className='line'>_________</span>
            <span className='text1'>Preguntas</span>
            <span className='text'>Frecuentes</span>
        </div>
      </div>
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
      <Join/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;