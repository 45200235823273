import React from "react";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from '../../assets/nb.png';
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import tick from "../../assets/tick.png";
import sergi2 from "../../assets/sergi2.jpeg"
import "./Reasons.css";
const Reasons = () => {
  return (
    <div className="Reasons" id='reasons'>
      <div className="left-r">
        <img src={sergi2} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>algunas razones</span>
        <div>
          <span className="stroke-text">Porque </span>
          <span>escogerme?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>Máxima individualización</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Contacto WhatsApp 24/7</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Entrenamiento adaptado a tus objetivos</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>La SALUD es el primer factor que tengo en cuenta</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Te implementaré un cambio de mentalidad </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
