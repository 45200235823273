import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import foto1 from "../assets/foto1.jpeg"
import foto2 from "../assets/foto2.jpeg"
import foto3 from "../assets/foto3.jpeg"
export const testimonialsData = [
  {
    image: foto1,
    review:
      "Gran cambio en cosa de 1 mes, cambio de hábitos, de mindset y de autoestima.",
    name: 'ROGER',
    status : '@NOGUERAFITNESS'
  },
  {
    image: foto2,
    review: 'Estoy feliz. Hoy después de un mes sin ver a varios trabajadores lo primero que me dicen es.. Joer, has adelgazado un huevo..',
    name: 'XAVI',
    status: '@NOGUERAFITNESS'
  },
  {
    image : foto3,
    review:'Solo darte las gracias porque gracias a ti empece a darle duro al gym y ahora no quiero parar hasta llegar a mi mejor version💪🏽',
    name: 'Eric',
    status: "@NOGUERAFITNESS "
  }
];
